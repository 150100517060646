<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

import { useSearchStore } from '~/stores/search'

const popoverHover: Ref<any> = ref(false)
const popoverTimeout: Ref<any> = ref(null)

const hoverPopover = (e: { target: { click: () => void } }, open: any) => {
  popoverHover.value = true
  if (!open && typeof e.target.click === 'function') {
    e.target.click()
  }
}

const closePopover = (close: () => void) => {
  popoverHover.value = false
  if (popoverTimeout.value) {
    clearTimeout(popoverHover.value)
  }
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value)
      close()
  }, 3)
}

const featured = ref([
  {
    name: 'Property for Sale',
    href: '/search',
    imageSrc: '/img/features/buy.jpg',
    imageAlt: 'A rural Cotswolds farm, how to buy land in the Cotswolds.',
    lqip: '/img/features/buy.lqip.webp',
  },
  {
    name: 'Cotswolds Lettings',
    href: '/search',
    imageSrc: '/img/features/let.jpeg',
    imageAlt: 'A pretty Cotswolds street with period properties.',
    lqip: '/img/features/let.lqip.webp',
  },
])

const featured2 = ref([
  {
    name: 'Equestrian Property',
    href: '/rural/equestrian-property',
    imageSrc: '/img/features/equestrian-property.jpg',
    imageAlt: 'Close up on a horse in a Cotswolds equestrian property.',
    lqip: '/img/features/equestrian-property.lqip.webp',
  },
  {
    name: 'Farms & Land',
    href: '/rural/land-sales-acquisitions',
    imageSrc: '/img/features/farms-land.jpg',
    imageAlt: 'A rural Cotswolds farm, how to buy land in the Cotswolds.',
    lqip: '/img/features/farms-land.lqip.webp',
  },
])

const searchStore = useSearchStore()
const router = useRouter()
const lettingSearch = async () => {
  await searchStore.setSearch({
    mapSearch: false,
    buyRent: 'rent',
    minprice: 0,
    maxprice: 10000,
    sortOrder: 'closest',
  })
  router.push('/search')
}
const propertySearch = async () => {
  await searchStore.setSearch({
    mapSearch: false,
    buyRent: 'buy',
    minprice: 0,
    maxprice: 10000000,
    sortOrder: 'closest',
  })
  router.push('/search')
}
</script>

<template>
  <div class="relative flex h-full">
    <Popover
      v-slot="{ open, close }"
      class="relative isolate z-50 flex"
    >
      <div class="flex">
        <PopoverButton
          id="buy-menu"
          class="font-semibold hover:opacity-80 h-full flex items-center gap-x-1 leading-6 outline-none focus:outline-none"
          @mouseover="(e) => hoverPopover(e, open)"
          @mouseleave="closePopover(close)"
        >
          Find a Property
          <Icon name="mdi:chevron-down" class="h-5 w-5" aria-hidden="true" />
        </PopoverButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-1"
      >
        <PopoverPanel
          v-slot="{ close }"
          class="fixed left-0 z-10 mt-16 flex w-screen"
        >
          <div
            class="max-w-8xl mx-auto px-4 py-8"
            @mouseover.prevent="popoverHover = true"
            @mouseleave="closePopover(close)"
          >
            <div class="bg-white shadow-lg rounded-lg overflow-hidden ring-1 ring-gray-200/30 text-gray-700">
              <div class="mx-auto grid max-w-8xl grid-cols-7 gap-y-12">
                <!-- Column 1 -->
                <div class="p-8 col-span-3">
                  <div class="grid grid-cols-2 grid-rows-1 gap-8 text-sm">
                    <div class="sale-search group aspect-h-2 aspect-w-3 overflow-hidden rounded-md bg-gray-100 col-span-1 relative">
                      <nuxt-img
                        :src="featured[0].imageSrc"
                        quality="80"
                        :alt="featured[0].imageAlt"
                        class="object-cover object-center group-hover:opacity-75"
                        :width="260"
                        :height="195"
                        :placeholder="featured[0].lqip"
                        preload
                      />
                      <div class="absolute opacity-20 bg-black inset-0" />
                      <div class="absolute bg-gradient-to-t from-black via-transparent to-transparent inset-0" />
                      <div class="bg-transparent p-4 text-base flex flex-col justify-end">
                        <UiBtn
                          class="text-white"
                          :padded="false"
                          variant="link"
                          color="white"
                          size="lg"
                          @click="propertySearch()"
                        >
                          <span class="absolute inset-0 text-start" aria-hidden="true" />
                          {{ featured[0].name }}
                        </UiBtn>
                      </div>
                    </div>
                    <div class="sale-search group aspect-h-2 aspect-w-3 overflow-hidden rounded-md bg-gray-100 col-span-1 relative">
                      <nuxt-img
                        :src="featured[1].imageSrc"
                        quality="80"
                        :alt="featured[1].imageAlt"
                        class="object-cover object-center group-hover:opacity-75"
                        :width="260"
                        :height="195"
                        :placeholder="featured[1].lqip"
                        preload
                      />
                      <div class="absolute opacity-20 bg-black inset-0" />
                      <div class="absolute bg-gradient-to-t from-black via-transparent to-transparent inset-0" />
                      <div class="bg-transparent p-4 text-base flex flex-col justify-end">
                        <UiBtn
                          class="text-white"
                          :padded="false"
                          variant="link"
                          color="white"
                          size="lg"
                          @click="lettingSearch()"
                        >
                          <span class="absolute inset-0" aria-hidden="true" />
                          {{ featured[1].name }}
                        </UiBtn>
                      </div>
                    </div>
                    <div v-for="(item) in featured2" :key="item.name" class="group aspect-h-2 aspect-w-3 overflow-hidden rounded-md bg-gray-100 col-span-1 relative">
                      <nuxt-img
                        v-if="item.imageSrc"
                        :src="item.imageSrc"
                        quality="80"
                        :alt="item.imageAlt"
                        class="object-cover object-center group-hover:opacity-75"
                        :width="260"
                        :height="195"
                        :placeholder="item.lqip"
                        preload
                      />
                      <div v-else class="bg-gradient-to-t from-forest-700 to-forest-500" />
                      <div v-if="item.imageSrc" class="absolute opacity-20 bg-black inset-0" />
                      <div v-if="item.imageSrc" class="absolute bg-gradient-to-t from-black via-transparent to-transparent inset-0" />
                      <div class="bg-transparent p-4 text-base flex flex-col justify-end">
                        <UiBtn
                          :to="item.href"
                          class="text-white"
                          :padded="false"
                          variant="link"
                          color="white"
                          size="lg"
                        >
                          <span class="absolute inset-0" aria-hidden="true" />
                          {{ item.name }}
                        </UiBtn>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Column 2 -->
                <div class="grid col-span-4 grid-cols-2">
                  <!-- Column 1A -->
                  <div class="bg-white border-r border-stone-200">
                    <div class="sell py-8 pr-4">
                      <h4 class="text-2xl font-semibold mb-2">
                        Buy a Property
                      </h4>
                      <p class="text-sm text-gray-700">
                        Find your perfect property in the Cotswolds. Share your wants, needs and ambitions with us, and our experts will tirelessly work to find the perfect match for you.
                      </p>
                      <UiBtn
                        variant="solid"
                        size="md"
                        color="forest"
                        label="Property Search"
                        :padded="true"
                        class="mb-3"
                        @click="propertySearch"
                      />
                    </div>
                    <div class="let ">
                      <h4 class="text-2xl font-semibold mb-2">
                        Rent a Property
                      </h4>
                      <p class="text-sm text-gray-700">
                        Find your next home in the Cotswolds.
                      </p>
                      <UiBtn
                        variant="solid"
                        size="md"
                        color="forest"
                        label="Property Search"
                        :padded="true"
                        class="mb-4"
                        @click="lettingSearch"
                      />
                    </div>
                  </div>
                  <!-- Column 1B -->
                  <div class="p-8 bg-stone-100">
                    <h3
                      class="text-2xl font-semibold leading-6 tracking-tight"
                    >
                      Cotswolds Property Services
                    </h3>
                    <p class="mt-2 text-sm text-gray-700">
                      Butler Sherborn is the Cotswolds property specialist, with offices in Cirencester, Stow-on-the-Wold and Burford, covering the whole of the Cotswolds.
                    </p>
                    <nuxt-link
                      to="/browse"
                      class="flex items-start gap-2 hover:bg-white/70 p-2 rounded-md"
                      @click="close"
                    >
                      <Icon name="fluent:real-estate-24-filled" class="text-lg text-forest-500 flex-none" aria-hidden="true" />
                      <div class="text-base font-semibold text-forest-500">
                        Browse our Latest Properties
                      </div>
                    </nuxt-link>

                    <nuxt-link
                      to="/property-services/off-market-properties"
                      class="flex items-start gap-2 hover:bg-white/70 p-2 rounded-md"
                      @click="close"
                    >
                      <Icon name="mdi:tag-hidden" class="text-lg text-forest-500 flex-none" aria-hidden="true" />
                      <div class="text-base font-semibold text-forest-500">
                        Off-Market Properties
                      </div>
                    </nuxt-link>

                    <nuxt-link
                      to="/property-services/buy"
                      class="flex items-start gap-2 hover:bg-white/70 p-2 rounded-md"
                      @click="close"
                    >
                      <Icon name="material-symbols:in-home-mode" class="text-lg text-forest-500 flex-none" aria-hidden="true" />
                      <div class="text-base font-semibold text-forest-500">
                        Register as a Buyer
                      </div>
                    </nuxt-link>
                    <nuxt-link
                      to="/property-services/rent"
                      class="flex items-start gap-2 hover:bg-white/70 p-2 rounded-md"
                      @click="close"
                    >
                      <Icon
                        name="material-symbols:outgoing-mail-outline-rounded" class="text-lg text-forest-500 flex-none" aria-hidden="true"
                      />
                      <div class="text-base font-semibold text-forest-500">
                        Register as a Tenant
                      </div>
                    </nuxt-link>

                    <h3 class="text-xl font-semibold leading-6 tracking-tight mt-8">
                      Questions?
                    </h3>
                    <p class="mt-2 text-sm text-gray-700">
                      Whatever your property ambitions, we’re here to help.
                    </p>
                    <UiBtn
                      variant="link"
                      size="md"
                      color="forest"
                      to="/contact"
                      label="Contact us"
                      :padded="false"
                      class="font-semibold"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
