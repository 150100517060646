<script setup lang="ts">
defineProps({
  fontClass: {
    type: String,
    required: false,
    default: '',
  },
})

const { exportBranches } = useContent()
const branches = ref(exportBranches())
</script>

<template>
  <template v-if="branches">
    <div
      v-for="branch in branches"
      :key="branch?.slug"
      class="block"
    >
      <div class="flex justify-between sm:max-w-72 mb-2 lg:mb-3">
        <nuxt-link
          :to="`/estate-agent/${branch?.slug}`"
          class="block "
          :class="fontClass ? fontClass : 'text-sm lg:text-base text-forest-100 hover:text-white'"
        >
          {{ branch?.name }}
        </nuxt-link>
        <nuxt-link
          :to="`tel:${branch?.tel}`"
          class="text-sm block"
          :class="fontClass ? fontClass : 'text-sm lg:text-base text-forest-100 hover:text-white'"
        >
          {{ branch?.tel }}
        </nuxt-link>
      </div>
    </div>
  </template>
</template>
