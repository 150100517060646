<script setup lang="ts">
</script>

<template>
  <div class="fixed bottom-0 w-full bg-white z-50">
    <div class="rounded-md bg-birch-200 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <Icon name="mdi:info" class="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div class="ml-3 flex-1 md:flex md:justify-between">
          <p class="text-sm font-semibold text-blue-700 mb-0 mt-0.5">
            You are viewing the preview version of the website, which includes draft content.
          </p>
          <p class="mt-3 text-sm md:ml-6 md:mt-0 mb-0">
            <a
              href="/crm/appraisal"
              class="rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              View Form Submissions
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
