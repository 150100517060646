<script setup lang="ts">
// type is successStories, promoted is true, categories array includes "sales"
const promotedSuccessStory = groq`*[_type == "successStories" && promoted == true && "sales" in categories[]]{
  heading,
  slug,
  clientQuote,
  pricing,
  image{
    "asset": {
      "_ref": asset._ref,
    }
  }
}[0] | order(date desc)`

const { urlFor } = useSanityImage()
const { truncate } = useTruncate()

const sanity = useSanity()
const { data: successStory, error } = await useAsyncData(promotedSuccessStory, () => sanity.fetch<Sanity.Default.Schema.SuccessStories>(promotedSuccessStory))

if (error.value) {
  // console.log('Error fetching promoted success story')
  console.error(error)
}
</script>

<template>
  <div v-if="successStory && successStory.image" class="group aspect-h-9 aspect-w-16 overflow-hidden rounded-md bg-gray-100 col-span-2 relative">
    <nuxt-img
      :src="urlFor(successStory.image.asset).width(480).height(270).fit('crop').url()"
      quality="85"
      :alt="successStory.image.alt"
      class="object-cover object-center group-hover:opacity-75"
      :width="480"
      :height="270"
    />
    <div class="absolute opacity-20 bg-black inset-0" />
    <div class="absolute bg-gradient-to-t from-black via-transparent to-transparent inset-0" />
    <div class="bg-transparent p-4 text-base flex flex-col justify-end">
      <UiBtn
        to="/success-stories"
        class="mb-2 text-white"
        :padded="false"
        variant="link"
        color="white"
        size="lg"
      >
        <span class="absolute inset-0" aria-hidden="true" />
        Success Stories
      </UiBtn>
      <p to="/success-stories" class="font-semibold text-gray-50 text-sm mb-0">
        {{ successStory.heading }}<span v-if="successStory.pricing">({{ successStory.pricing }})</span>
      </p>
      <p aria-hidden="true" class=" text-gray-50 mb-0 text-sm">
        "{{ truncate(successStory.clientQuote, 75) }}"
      </p>
    </div>
  </div>
</template>
