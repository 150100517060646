<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useUiStore } from '~/stores/ui'

const uiStore = useUiStore()
const { showMobile } = storeToRefs(uiStore)

const router = useRouter()
const navigateTo = (link: string) => {
  uiStore.hideMobileMenu()
  setTimeout(() => {
    router.push(link)
  }, 50)
}

const menu = ref([
  {
    name: 'Property Search',
    link: '/search',
  },
  {
    name: 'Sell Your Property',
    link: '/property-services/sell',
  },
  {
    name: 'Landlord Services',
    link: '/property-services/landlords',
  },
])

const secondaryMenu = ref([
  {
    name: 'The Team',
    link: '/about',
  },
  {
    name: 'Rural & Professional',
    link: '/rural',
  },
  {
    name: 'News & Comment',
    link: '/articles',
  },
  {
    name: 'Equestrian',
    link: '/rural/equestrian-property',
  },
  {
    name: 'Testimonials',
    link: '/success-stories',
  },
  {
    name: 'Land & Farms',
    link: '/rural/land-sales-acquisitions',
  },
  {
    name: 'Contact',
    link: '/contact',
  },

  {
    name: 'Register for Alerts',
    link: '/property-services/buy',
  },
])

const { exportBranches } = useContent()
const branches = ref(exportBranches())

const social = ref([
  {
    name: 'Facebook',
    href: 'https://en-gb.facebook.com/butlersherborn/',
    icon: 'mdi:facebook',
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/butlersherborn/',
    icon: 'mdi:instagram',
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/butler-sherborn',
    icon: 'mdi:linkedin',
  },
])
</script>

<template>
  <div>
    <USlideover v-model="showMobile" class="">
      <div class="min-h-screen h-full bg-stone-200 overflow-scroll">
        <div class="flex items-center justify-between px-6 py-4 border-b border-stone-400">
          <nuxt-link
            to="/"
            class="hover:opacity-85"
          >
            <nuxt-img
              src="/logomark.png"
              alt="Butler Sherborn logomark"
              width="40"
              height="40"
              quality="85"
              class="opacity-90 mr-1"
            />
          </nuxt-link>
          <!-- closer -->
          <div class="text-forest-400 hover:text-forest-500 focus:outline-none">
            <button @click="uiStore.hideMobileMenu()">
              <span class="sr-only">Close menu</span>
              <Icon name="heroicons:x-mark" class="text-4xl text-gray-900" aria-hidden="true" />
            </button>
          </div>
        </div>

        <nav class="">
          <div class="grid px-6 py-1.5 border-b border-stone-300">
            <span
              v-for="item in menu"
              :key="item.name"
              :to="item.link"
              class="hover:text-primary-400 cursor-pointer py-2 text-xl font-bold text-gray-700"
              @click="navigateTo(item.link)"
            >
              {{ item.name }}
            </span>
          </div>
          <div class="grid grid-cols-2 gap-x-8 px-6 py-2">
            <span
              v-for="item in secondaryMenu"
              :key="item.name"
              :to="item.link"
              class="hover:text-primary-400 cursor-pointer py-1.5 text-base font-bold text-gray-700"
              @click="navigateTo(item.link)"
            >
              {{ item.name }}
            </span>
          </div>
        </nav>
        <!-- Our Offices -->
        <div class="border-t border-stone-400 md:col-span-3 px-6 py-4">
          <h3 class="text-lg lg:text-xl font-bold text-gray-700 mb-1.5">
            Offices
          </h3>
          <div
            v-for="branch in branches"
            :key="branch.slug"
          >
            <div class="grid grid-cols-2 gap-x-8 py-1">
              <div
                class="text-sm text-gray-700 hover:text-forest-600 cursor-pointer"
                @click="navigateTo(`/estate-agent/${branch.slug}`)"
              >
                {{ branch.name }}
              </div>
              <nuxt-link
                :to="`tel:${branch?.tel}`"
                class="text-sm text-gray-700 hover:text-forest-600 cursor-pointer"
              >
                {{ branch?.tel }}
              </nuxt-link>
            </div>
          </div>
        </div>
        <div class="border-t border-stone-400 px-6 py-4 text-xs text-gray-700">
          <div class="flex space-x-3 mb-3 text-forest-700">
            <nuxt-link
              v-for="item in social"
              :key="item.name"
              :href="item.href"
              class="block"
            >
              <span class="sr-only">{{ item.name }}</span>
              <Icon :name="item.icon" class="h-7 w-7" aria-hidden="true" />
            </nuxt-link>
          </div>
          <p class="mb-0">
            <strong>The Cotswolds Property Specialists.</strong><br>
            Estate Agents, Letting Agents and Rural Property Consultants.
          </p>
          <div class="py-4">
            <UButton
              color="forest"
              variant="solid"
              :block="true"
              label="Market Appraisal"
              size="lg"
              class="text-lg font-bold"
              :ui="{ rounded: 'rounded-full' }"
              @click="navigateTo('/property-services/sell')"
            />
          </div>
        </div>
      </div>
    </USlideover>
  </div>
</template>
