<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

const { exportServices } = useContent()
const services = ref(exportServices())

const popoverHover: Ref<any> = ref(false)
const popoverTimeout: Ref<any> = ref(null)

const hoverPopover = (e: { target: { click: () => void } }, open: any) => {
  popoverHover.value = true
  if (!open && typeof e.target.click === 'function') {
    e.target.click()
  }
}

const closePopover = (close: () => void) => {
  popoverHover.value = false
  if (popoverTimeout.value) {
    clearTimeout(popoverHover.value)
  }
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value)
      close()
  }, 3)
}
</script>

<template>
  <div class="relative flex h-full">
    <Popover
      v-slot="{ open, close }"
      class="relative isolate z-50 flex"
    >
      <div class="flex">
        <PopoverButton
          id="about-menu"
          class="font-semibold h-full flex items-center gap-x-1 leading-6 outline-none focus:outline-none hover:opacity-80"
          @mouseover="(e) => hoverPopover(e, open)"
          @mouseleave="closePopover(close)"
        >
          About
          <Icon name="mdi:chevron-down" class="h-5 w-5" aria-hidden="true" />
        </PopoverButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-1"
      >
        <PopoverPanel
          v-slot="{ close }"
          class="fixed left-0 z-10 mt-16 flex w-screen"
        >
          <div
            class="max-w-8xl mx-auto px-4 py-8"
            @mouseover.prevent="popoverHover = true"
            @mouseleave="closePopover(close)"
          >
            <div class="bg-white shadow-lg rounded-lg overflow-hidden ring-1 ring-gray-200/30 text-gray-700">
              <div class="mx-auto grid max-w-8xl grid-cols-7 gap-y-12">
                <!-- Column 1 -->
                <div class="p-8 pr-10 col-span-3 bg-stone-100 border-l border-stone-200">
                  <UiBtn
                    variant="link"
                    size="xl"
                    color="forest"
                    href="/articles"
                    label="News & Comment"
                    :padded="false"
                    class="mb-3"
                  />
                  <HeaderMenuDropDownPromotedLatestNews />
                </div>
                <div class="grid col-span-4 grid-cols-2">
                  <!-- Column 1A -->
                  <div class="rural p-8">
                    <h3
                      class="text-2xl font-semibold leading-6 tracking-tight"
                    >
                      Services
                    </h3>
                    <div class="mt-5 flow-root">
                      <div
                        v-for="service in services"
                        :key="service.slug"
                        class="block"
                      >
                        <div class="mb-2 lg:mb-1.5">
                          <nuxt-link
                            :to="`${service?.slug}`"
                            class="block hover:bg-stone-100 p-2 leading-5 rounded-md text-lg font-semibold text-forest-500 hover:text-forest-700"
                          >
                            <span>{{ service.name }}</span><br>
                            <span class="mt-1 font-normal text-sm text-gray-700 mb-0 line-clamp-2">
                              {{ service.description }}
                            </span>
                          </nuxt-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="offices p-8">
                    <h3
                      class="text-2xl font-semibold leading-6 tracking-tight"
                    >
                      Offices
                    </h3>
                    <div class="mt-6 flow-root">
                      <FooterBranches
                        font-class="text-lg font-semibold leading-6 text-forest-500 hover:text-forest-700"
                      />
                    </div>
                    <div class="mt-4 flow-root">
                      <UiBtn
                        variant="link"
                        size="md"
                        color="forest"
                        to="/about"
                        label="The Team"
                        :padded="false"
                        class="mb-3 font-semibold"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
