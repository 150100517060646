<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core'

const { y } = useWindowScroll()

const scrollDirection: Ref<null | 'none' | 'up' | 'down'> = ref('none')
const offset = 112

watch(y, (newY, oldY) => {
  if (newY > oldY) {
    scrollDirection.value = 'down'
  }
  else {
    scrollDirection.value = 'up'
  }
})

const showMenu: ComputedRef<'fixed' | 'sticky' | 'hidden'> = computed(() => {
  // return either 'fixed', 'sticky' or 'hidden'
  if (scrollDirection.value === 'none') {
    return 'hidden'
  }
  if (y.value < offset) {
    return 'fixed'
  }
  if ((y.value > offset + 400) && (scrollDirection.value === 'up')) {
    return 'sticky'
  }
  return 'hidden'
})

// get the initial window scroll position on mount
onMounted(() => {
  y.value = window.scrollY
  if (y.value > 0) {
    scrollDirection.value = 'down'
  }
  else {
    scrollDirection.value = 'up'
  }
})
</script>

<template>
  <header
    id="main-menu"
    class="fixed top-0 transform w-full z-50 transition duration-500 ease-in-out translate-y-[-100%] bg-transparent"
    :class="{
      'is-fixed': showMenu === 'fixed',
      'is-sticky': showMenu === 'sticky',
    }"
  >
    <div class="flex">
      <div class="w-full flex flex-col border-b border-white/20">
        <nav
          class="w-full h-full container-8xl"
        >
          <HeaderMenuDesktop class="py-4" />
          <HeaderMenuMobile />
        </nav>
      </div>
    </div>
  </header>
</template>
