<script setup lang="ts">
const sanity = useSanity()

// all articles data
const articlesQuery = groq`*[_type == "articles"]{
  heading,
  slug,
  image{
    ...,
    "asset": {
      "_ref": asset._ref,
      "metadata": {
        "lqip": asset->metadata.lqip,
      }
    }
  },
  date,
  _id,
} | order(date desc) [0...4]`

const { data: articles, error, pending } = await useAsyncData(articlesQuery, async () => sanity.fetch<Sanity.Default.Schema.Articles[]>(articlesQuery))

if (error.value) {
  console.error(error)
  throw createError({
    status: 404,
    message: 'Page not found',
  })
}

const { urlFor } = useSanityImage()
</script>

<template>
  <div v-if="pending" class="grid grid-cols-2 gap-8">
    <div>
      <USkeleton class="w-full aspect-[7/5] mb-4" />
      <USkeleton class="h-6 w-36 mb-2" />
    </div>
    <div>
      <USkeleton class="w-full aspect-[7/5] mb-4" />
      <USkeleton class="h-6 w-36 mb-2" />
    </div>
  </div>
  <div v-if="!pending && articles" class="grid grid-cols-2 gap-8">
    <template
      v-for="(article) in articles"
      :key="article?._id"
    >
      <div
        v-if="article"
        class="relative group h-full flex flex-col justify-between"
      >
        <nuxt-link
          :to="`/articles/${article.slug.current}`"
          class="inset-0 absolute z-10"
        >
          <span class="sr-only">Read Article</span>
        </nuxt-link>
        <div class="header overflow-hidden">
          <nuxt-img
            v-if="article.image"
            :src="urlFor(article.image.asset).width(360).height(180).fit('crop').url()"
            quality="85"
            :alt="article.image.alt"
            :width="360"
            :height="180"
            sizes="sm:98vw md:48vw lg:370px"
            class="object-cover object-center w-full rounded aspect-[16/9] group-hover:opacity-90"
            preload
            :placeholder="article.image.asset?.metadata.lqip"
          />

          <div class="body py-3">
            <div v-if="article.date" class="text-gray-700 mb-2 text-xs font-semibold">
              {{ new Date(article.date).toLocaleDateString('en-UK', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }) }}
            </div>
            <h3 class="text-base font-semibold text-gray-700 group-hover:text-forest-600 mb-0">
              {{ article.heading }}
            </h3>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
